#wp_tag_cloud div {
	line-height: 1.6em;
}

#wp-calendar {
	width: 100%;
}

#wp-calendar caption {
	color: #222;
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 4px;
	text-align: left;
}

#wp-calendar thead {
	font-size: 11px;
}

#wp-calendar thead th {
}

#wp-calendar tbody {
	color: #aaa;
}

#wp-calendar tbody td {
	background: #f5f5f5;
	border: 1px solid #fff;
	padding: 3px 0 2px;
	text-align: center;
}

#wp-calendar tbody .pad {
	background: none;
}

#wp-calendar tfoot #next {
	text-align: right;
}

.widget_rss a.rsswidget {
	color: #000;
}

.widget_rss a.rsswidget:hover {
	color: #ff4b33;
}

.widget_rss .widget-title img {
	width: 11px;
	height: 11px;
}

// ----------------------------------------------------------
// ::
// ----------------------------------------------------------
.widget_links {
	.blogroll {
		margin: 10px 0;
		
		li {
			margin-bottom: 3px;
		}
	}
}


// ----------------------------------------------------------
// :: SEARCH FORM WIDGET
// ----------------------------------------------------------

.search-form-wrapper {
	input[type="text"] {
		width: 100%;
		margin-top: 15px;
		margin-bottom: 15px;
		box-sizing: border-box;
		padding: 5px 10px;
	}
}


// ----------------------------------------------------------
// :: recent-posts-extended
// ----------------------------------------------------------

.recent-posts-extended .rpwe-ul {
	.rpwe-li {
		margin-top: 10px;
		border-bottom: 3px solid #000;
	}
}


// ----------------------------------------------------------
// :: decent-comments
// ----------------------------------------------------------

.widget_decent_comments_widget {
	margin-top: 15px;
}

.decent-comments {
	li {
		margin-top: 10px;
		border-bottom: 3px solid #000;
		
		
		.comment-author {
			font-weight: bold;
		}
		
		.comment-excerpt {
			font-style: italic;
			font-size: 11px;
			margin-bottom: 10px;
		}
	}
}

// ----------------------------------------------------------
// :: custom-html-widget
// ----------------------------------------------------------

.custom-html-widget {
	a {
		text-decoration: underline;
	}
}


// ----------------------------------------------------------
// ::
// ----------------------------------------------------------

#custom_html-2 {
	margin-top: 15px;
	margin-bottom: 30px;
	
	.widget-title{
		margin-bottom: 10px;
	}
	
}
