// ----------------------------------------------------------
// :: SIDEBAR
// ----------------------------------------------------------

.widget-area ul {
	list-style: none;
	margin: 0;
}




widget-area p {
	margin-bottom: 5px;
}


.widget-area select {
	max-width: 100%;
}

.widget_search #s { /* This keeps the search inputs in line */
	width: 60%;
}

.widget_search label {
	display: none;
}

.widget-container {
	margin: 0 0 5px 0;
}



.widget-area a:active,
.widget-area a:hover {
	text-decoration: underline;
}

.widget-area .entry-meta {
	font-size: 11px;
}
