.author-item{
	display: flex;
	align-items: center;
	flex-direction: row;
	padding-bottom: 5px;
	margin-bottom: 10px;
	margin-top: 15px;
	border-bottom: #000 5px solid;
	
	
	.image-link,
	.author-text{
		box-sizing: border-box;
	}
	
	
	.image-link{
		display: block;
		width: 24%;
		padding-right: 15px;
		
		img{
			width: 100%;
			height: auto;
		}
	}
	
	.author-text{
		width: 76%;
	}
	
	
}


.author-title{
	margin-bottom: 5px;
}

.author-title a{
	text-decoration: none;
	font-weight: bold;
}
