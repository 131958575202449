#wrapper{
	width: 100%;
	max-width: $screen-l;
	padding: 30px 15px 15px;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-sizing: border-box;
	

	
	position: relative;
	
	&:after{
		content: '';
		display: block;
		width: 50px;
		height: 50px;
		background-image: url("imatges/fons_decoration.png");
		background-repeat: no-repeat;
		background-position: right top;
		position: absolute;
		top: 0;
		right: 15px;
		
		@include breakpoint{
			top: 0;
			right: -50px;
		}
		
	}
}

.left-column{
	padding-right: 15px;
	@include breakpoint{
		padding-right: 30px;
	}
}

div.page{
	width: 88%;
	@include breakpoint{
		width: 80%;
	}
}


// ----------------------------------------------------------
// ::
// ----------------------------------------------------------


#main{
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	
	
	#container{
		width: 100%;
	}
	
	.widget-area{
		width: 100%;
	}
	
	@include breakpoint{
		flex-direction: row;
		
		#container{
			padding-right: 30px;
			width: 72%;
		}
		
		.widget-area{
			width: 28%
		}
	}
	
}





// ----------------------------------------------------------
// ::
// ----------------------------------------------------------

#logo img{
	width: 50px;
	height: auto;
	
	@include breakpoint{
		width: 90px;
	}
}


#header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	
	.slogan{
		display: none;
		padding-top: 10px;
		
		@include breakpoint{
			display: block;
		}
	}
	
	#access{
		@include breakpoint{
			margin-right: 20px;
		}
	}
	
}





