/*
Theme Name: comarcal
Theme URI: http://wordpress.org/
Description: The 2010 theme for WordPress is stylish, customizable, simple, and readable -- make it yours with a custom menu, header image, and background. Twenty Ten supports six widgetized areas (two in the sidebar, four in the footer) and featured images (thumbnails for gallery posts and custom header images for posts and pages). It includes stylesheets for print and the admin Visual Editor, special styles for posts in the "Asides" and "Gallery" categories, and has an optional one-column page template that removes the sidebar.
Author: the WordPress team
Version: 1.2
License: GNU General Public License
License URI: license.txt
Tags: black, blue, white, two-columns, fixed-width, custom-header, custom-background, threaded-comments, sticky-post, translation-ready, microformats, rtl-language-support, editor-style, custom-menu
*/


/* =Reset default browser CSS. Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html
-------------------------------------------------------------- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

img {
	margin-bottom: 10px;
}

body {
	line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-weight: normal;
}

ol, ul {
	list-style: none;
}

blockquote {
	quotes: none;
}

blockquote:before, blockquote:after {
	content: '';
	content: none;
}

del {
	text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a img {
	border: none;
}



/* =Fonts
-------------------------------------------------------------- */
body,
input,
textarea,
.page-title span,
.pingback a.url {
	font-family: Helvetica, Arial, "Bitstream Charter", serif;
}
h3#comments-title,
h3#reply-title,
#access .menu,
#access div.menu ul,
#cancel-comment-reply-link,
.form-allowed-tags,
#site-info,
#site-title,
#wp-calendar,
.comment-meta,
.comment-body tr th,
.comment-body thead th,
.entry-content label,
.entry-content tr th,
.entry-content thead th,
.entry-meta,
.entry-title,
.entry-utility,
#respond label,
.navigation,
.page-title,
.pingback p,
.reply,
.widget-title,
.wp-caption-text {
	font-family: Helvetica, Arial, "Nimbus Sans L", sans-serif;
}
input[type=submit] {
	font-family: Helvetica, Arial, "Nimbus Sans L", sans-serif;
	color: black;
}
pre {
	font-family: "Courier 10 Pitch", Courier, monospace;
}
code {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

small {
	font-family: Helvetica, Arial, "Nimbus Sans L", sans-serif;
	font-size: 10px;
}



/* =Global Elements
-------------------------------------------------------------- */

/* Main global 'theme' and typographic styles */
body {
	background: #fff;
}

body,
input,
textarea {
	color: #666;
	font-size: 12px;
	line-height: 1.5;
}

hr {
	background-color: #e7e7e7;
	border: 0;
	clear: both;
	height: 1px;
	margin-bottom: 18px;
}

/* Text elements */
p {
	margin-bottom: 2px;
}

ul {
	list-style: square;
	margin: 0 0 18px 1.5em;
}

ol {
	list-style: decimal;
	margin: 0 0 18px 1.5em;
}

ol ol {
	list-style: upper-alpha;
}

ol ol ol {
	list-style: lower-roman;
}

ol ol ol ol {
	list-style: lower-alpha;
}

ul ul,
ol ol,
ul ol,
ol ul {
	margin-bottom: 0;
}
dl {
	margin: 0 0 24px 0;
}
dt {
	font-weight: bold;
}
dd {
	margin-bottom: 18px;
}
strong {
	font-weight: bold;
}
cite,
em,
i {
	font-style: italic;
}
big {
	font-size: 131.25%;
}
ins {
	background: #ffc;
	text-decoration: none;
}
blockquote {
	font-style: italic;
	padding: 0 3em;
}
blockquote cite,
blockquote em,
blockquote i {
	font-style: normal;
}
pre {
	background: #f7f7f7;
	color: #222;
	line-height: 12px;
	margin-bottom: 18px;
	padding: 1.5em;
}
abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}
sup,
sub {
	height: 0;
	line-height: 1;
	position: relative;
	vertical-align: baseline;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}
input[type="text"],
textarea {
	background: #f9f9f9;
	border: 1px solid #ccc;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1);
	padding: 2px;
}
a:link {
	color: #000000;
}
a:visited {
	color: #000000;
}
a:active,
a:hover {
	color: #ff4b33;
}

/* Text meant only for screen readers */
.screen-reader-text {
	position: absolute;
	left: -9000px;
}






/* =Content
-------------------------------------------------------------- */

#content {
	margin-bottom: 36px;
}

#content,
#content input,
#content textarea {
	font-size: 14px;
}


#content p,
#content ul,
#content ol,
#content dd,
#content pre,
#content hr {
	margin-bottom: 10px;
}
#content ul ul,
#content ol ol,
#content ul ol,
#content ol ul {
	margin-bottom: 0;
}
#content pre,
#content kbd,
#content tt,
#content var {
	font-size: 15px;
	line-height: 21px;
}
#content code {
	font-size: 13px;
}
#content dt,
#content th {
	color: #000;
}



#content table {
	border: 1px solid #e7e7e7;
	margin: 0 -1px 24px 0;
	text-align: left;
	width: 100%;
}
#content tr th,
#content thead th {
	color: #888;
	font-size: 12px;
	font-weight: bold;
	line-height: 12px;
	padding: 9px 24px;
}
#content tr td {
	border-top: 1px solid #e7e7e7;
	padding: 6px 24px;
}
#content tr.odd td {
	background: #f2f7fc;
}


.hentry {
	margin: 0 0 20px 0;
}

.home .sticky {
	background: #f2f7fc;
	border-top: 4px solid #000;
	margin-left: -20px;
	margin-right: -20px;
	padding: 18px 20px;
}

.single .hentry {
	margin: 0 0 36px 0;
}



.entry-meta {
	color: #888;
	font-size: 12px;
}

.entry-meta abbr,
.entry-utility abbr {
	border: none;
}

.entry-meta abbr:hover,
.entry-utility abbr:hover {
	border-bottom: 1px dotted #666;
}

.entry-content,
.entry-summary {
	clear: both;
	padding: 12px 0 0 0;
	text-align: justify;
}


#content .entry-summary p:last-child {
	margin-bottom: 12px;
}
.entry-content fieldset {
	border: 1px solid #e7e7e7;
	margin: 0 0 24px 0;
	padding: 24px;
}
.entry-content fieldset legend {
	background: #fff;
	color: #000;
	font-weight: bold;
	padding: 0 24px;
}
.entry-content input {
	margin: 0 0 24px 0;
}
.entry-content input.file,
.entry-content input.button {
	margin-right: 24px;
}
.entry-content label {
	color: #888;
	font-size: 12px;
}
.entry-content select {
	margin: 0 0 24px 0;
}
.entry-content sup,
.entry-content sub {
	font-size: 10px;
}
.entry-content blockquote.left {
	float: left;
	margin-left: 0;
	margin-right: 24px;
	text-align: right;
	width: 33%;
}
.entry-content blockquote.right {
	float: right;
	margin-left: 24px;
	margin-right: 0;
	text-align: left;
	width: 33%;
}
.page-link {
	clear: both;
	color: #000;
	font-weight: bold;
	margin: 0 0 22px 0;
	word-spacing: 0.5em;
}
.page-link a:link,
.page-link a:visited {
	background: #f1f1f1;
	color: #333;
	font-weight: normal;
	padding: 0.5em 0.75em;
	text-decoration: none;
}
.home .sticky .page-link a {
	background: #d9e8f7;
}
.page-link a:active,
.page-link a:hover {
	color: #ff4b33;
}
body.page .edit-link {
	clear: both;
	display: block;
}
#entry-author-info {
	background: #f2f7fc;
	border-top: 4px solid #000;
	clear: both;
	font-size: 14px;
	line-height: 20px;
	margin: 24px 0;
	overflow: hidden;
	padding: 18px 20px;
}
#entry-author-info #author-avatar {
	background: #fff;
	border: 1px solid #e7e7e7;
	float: left;
	height: 60px;
	margin: 0 -104px 0 0;
	padding: 11px;
}
#entry-author-info #author-description {
	float: left;
	margin: 0 0 0 104px;
}
#entry-author-info h2 {
	color: #000;
	font-size: 100%;
	font-weight: bold;
	margin-bottom: 0;
}
.entry-utility {
	clear: both;
	color: #888;
	font-size: 12px;
	line-height: 12px;
}
.entry-meta a,
.entry-utility a {
	color: #888;
}
.entry-meta a:hover,
.entry-utility a:hover {
	color: #ff4b33;
}
#content .video-player {
	padding: 0;
}


/* =Asides
-------------------------------------------------------------- */

.home #content .format-aside p,
.home #content .category-asides p {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 5px;
	margin-top: 0;
}
.home .hentry.format-aside,
.home .hentry.category-asides {
	padding: 0;
}
.home #content .format-aside .entry-content,
.home #content .category-asides .entry-content {
	padding-top: 0;
}


/* =Gallery listing
-------------------------------------------------------------- */

.format-gallery .size-thumbnail img,
.category-gallery .size-thumbnail img {
	border: 10px solid #f1f1f1;
	margin-bottom: 0;
}
.format-gallery .gallery-thumb,
.category-gallery .gallery-thumb {
	float: left;
	margin-right: 20px;
	margin-top: -4px;
}
.home #content .format-gallery .entry-utility,
.home #content .category-gallery .entry-utility {
	padding-top: 4px;
}


/* =Attachment pages
-------------------------------------------------------------- */

.attachment .entry-content .entry-caption {
	font-size: 140%;
	margin-top: 24px;
}
.attachment .entry-content .nav-previous a:before {
	content: '\2190\00a0';
}
.attachment .entry-content .nav-next a:after {
	content: '\00a0\2192';
}


/* =Images
-------------------------------------------------------------- */

/*
Resize images to fit the main content area.
- Applies only to images uploaded via WordPress by targeting size-* classes.
- Other images will be left alone. Use "size-auto" class to apply to other images.
*/
img.size-auto,
img.size-full,
img.size-large,
img.size-medium,
.attachment img {
	max-width: 100%; /* When images are too wide for containing element, force them to fit. */
	height: auto; /* Override height to match resized width for correct aspect ratio. */
}
.alignleft,
img.alignleft {
	display: inline;
	float: left;
	margin-right: 24px;
	margin-top: 4px;
}
.alignright,
img.alignright {
	display: inline;
	float: right;
	margin-left: 24px;
	margin-top: 4px;
}
.aligncenter,
img.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
img.alignleft,
img.alignright,
img.aligncenter {
	margin-bottom: 12px;
}
.wp-caption {
	background: #f1f1f1;
	line-height: 12px;
	margin-bottom: 20px;
	max-width: 632px !important; /* prevent too-wide images from breaking layout */
	padding: 4px;
	text-align: center;
}
.wp-caption img {
	margin: 5px 5px 0;
}
.wp-caption p.wp-caption-text {
	color: #888;
	font-size: 12px;
	margin: 5px;
}
.wp-smiley {
	margin: 0;
}
.gallery {
	margin: 0 auto 18px;
}
.gallery .gallery-item {
	float: left;
	margin-top: 0;
	text-align: center;
	width: 33%;
}
.gallery-columns-2 .gallery-item {
	width: 50%;
}
.gallery-columns-4 .gallery-item {
	width: 25%;
}
.gallery img {
	border: 2px solid #cfcfcf;
}
.gallery-columns-2 .attachment-medium {
	max-width: 92%;
	height: auto;
}
.gallery-columns-4 .attachment-thumbnail {
	max-width: 84%;
	height: auto;
}
.gallery .gallery-caption {
	color: #888;
	font-size: 12px;
	margin: 0 0 12px;
}
.gallery dl {
	margin: 0;
}
.gallery img {
	border: 10px solid #f1f1f1;
}
.gallery br+br {
	display: none;
}
#content .attachment img {/* single attachment images should be centered */
	display: block;
	margin: 0 auto;
}


/* =Navigation
-------------------------------------------------------------- */

.navigation {
	color: #888;
	font-size: 12px;
	line-height: 18px;
	overflow: hidden;
}
.navigation a:link,
.navigation a:visited {
	color: #888;
	text-decoration: none;
}
.navigation a:active,
.navigation a:hover {
	color: #ff4b33;
}
.nav-previous {
	float: left;
	width: 50%;
}
.nav-next {
	float: right;
	text-align: right;
	width: 50%;
}
#nav-above {
	margin: 0 0 18px 0;
}
#nav-above {
	display: none;
}
.paged #nav-above,
.single #nav-above {
	display: block;
}
#nav-below {
	margin: -18px 0 0 0;
}


/* =Comments
-------------------------------------------------------------- */
#comments {
	clear: both;
}
#comments .navigation {
	padding: 0 0 18px 0;
}
h3 {
	text-align: left;
}
h3#comments-title,
h3#reply-title {
	color: #000;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 0;
}
h3#comments-title {
	padding: 24px 0;
}
.commentlist {
	list-style: none;
	margin: 0;
}
.commentlist li.comment {
	border-bottom: 1px solid #e7e7e7;
	line-height: 18px;
	margin: 0 0 24px 0;
	padding: 0 0 0 56px;
	position: relative;
}
.commentlist li:last-child {
	border-bottom: none;
	margin-bottom: 0;
}
#comments .comment-body ul,
#comments .comment-body ol {
	margin-bottom: 18px;
}
#comments .comment-body p:last-child {
	margin-bottom: 6px;
}
#comments .comment-body blockquote p:last-child {
	margin-bottom: 24px;
}
.commentlist ol {
	list-style: decimal;
}
.commentlist .avatar {
	position: absolute;
	top: 4px;
	left: 0;
}
.comment-author {
}
.comment-author cite {
	color: #000;
	font-style: normal;
	font-weight: bold;
}
.comment-author .says {
	font-style: italic;
}
.comment-meta {
	font-size: 12px;
	margin: 0 0 18px 0;
}
.comment-meta a:link,
.comment-meta a:visited {
	color: #888;
	text-decoration: none;
}
.comment-meta a:active,
.comment-meta a:hover {
	color: #ff4b33;
}
.commentlist .even {
}
.commentlist .bypostauthor {
}
.reply {
	font-size: 12px;
	padding: 0 0 24px 0;
}
.reply a,
a.comment-edit-link {
	color: #888;
}
.reply a:hover,
a.comment-edit-link:hover {
	color: #ff4b33;
}
.commentlist .children {
	list-style: none;
	margin: 0;
}
.commentlist .children li {
	border: none;
	margin: 0;
}
.nopassword,
.nocomments {
	display: none;
}
#comments .pingback {
	border-bottom: 1px solid #e7e7e7;
	margin-bottom: 18px;
	padding-bottom: 18px;
}
.commentlist li.comment+li.pingback {
	margin-top: -6px;
}
#comments .pingback p {
	color: #888;
	display: block;
	font-size: 12px;
	line-height: 18px;
	margin: 0;
}
#comments .pingback .url {
	font-size: 13px;
	font-style: italic;
}



/* =Widget Areas
-------------------------------------------------------------- */





/* =Footer
-------------------------------------------------------------- */

#footer {
	margin-bottom: 20px;
}
#colophon {
	border-top: 0px solid #000;
	margin-top: -4px;
	overflow: hidden;
	padding: 18px 0;
}
#site-info {
	font-weight: bold;
}
#site-info a {
	color: #000;
	text-decoration: none;
}
#site-generator {
	font-style: italic;
	position: relative;
}
#site-generator a {
	background: url(images/wordpress.png) center left no-repeat;
	color: #666;
	display: inline-block;
	line-height: 16px;
	padding-left: 20px;
	text-decoration: none;
}
#site-generator a:hover {
	text-decoration: underline;
}
img#wpstats {
	display: block;
	margin: 0 auto 10px;
}


.comment-body {
	font-size: 11px;
}

#logoc a {
	display: block;
	height: 100%;
	width: 100%;
}

.cf-after span{
	width:300px;
}

.cf-after label{
	width:300px;
}

// ----------------------------------------------------------
// :: read-more
// ----------------------------------------------------------

.read-more{
	text-decoration: none;
	display: block;
	margin-top: 5px;
}
