/* =Menu
-------------------------------------------------------------- */

#access {
	display: flex;
	flex-direction: column;
	width: 100%;
	
	@include breakpoint{
		flex-direction: row;
		width: initial;
		
	}
}



#access .menu-header ul,
div.menu ul {
	list-style: none;
	margin: 0;
}

#access .menu-header li,
div.menu li {
	position: relative;
}

#access .menu-header1 ul,
div.menu ul {
	list-style: none;
	
}

#access .menu-header1 li,
div.menu li {
	
	position: relative;
}

#access a {
	color: #000;
	display: block;
	line-height: 15px;
	padding: 3px 10px;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 2px;
	
	@include breakpoint{
		font-size: 12px;
		margin-bottom: 0px;
		line-height: 13px;
	}
	
}

#access ul ul {
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
	display: none;
	position: absolute;
	top: 38px;
	left: 0;
	float: left;
	width: 180px;
	z-index: 99999;
}

#access ul ul li {
	min-width: 180px;
}

#access ul ul ul {
	left: 100%;
	top: 0;
}

#access ul ul a {
	background: #333;
	line-height: 1em;
	padding: 10px;
	width: 160px;
	height: auto;
}

#access li:hover > a,
#access ul ul :hover > a {
	background: #333;
	color: #fff;
}

#access ul li:hover > ul {
	display: block;
}

#access ul li.current_page_item > a,
#access ul li.current-menu-ancestor > a,
#access ul li.current-menu-item > a,
#access ul li.current-menu-parent > a {
	color: #a8a5a2;
}

#access ul li.current_page_item.menubloc > a,
#access ul li.current-menu-ancestor.menubloc > a,
#access ul li.current-menu-item.menubloc > a,
#access ul li.current-menu-parent.menubloc > a {
	color: #fbaa4f;
}

#access ul li.current_page_item.menubiblioteca > a,
#access ul li.current-menu-ancestor.menubiblioteca > a,
#access ul li.current-menu-item.menubiblioteca > a,
#access ul li.current-menu-parent.menubiblioteca > a {
	color: #aa7239;
}

#access ul li.current_page_item.menuautors > a,
#access ul li.current-menu-ancestor.menuautors > a,
#access ul li.current-menu-item.menuautors > a,
#access ul li.current-menu-parent.menuautors > a {
	color: #e85c5b;
}

#access ul li.current_page_item.menumur > a,
#access ul li.current-menu-ancestor.menumur > a,
#access ul li.current-menu-item.menumur > a,
#access ul li.current-menu-parent.menumur > a {
	color: #9d476e;
}

#access ul li.current_page_item.menupremsa > a,
#access ul li.current-menu-ancestor.menupremsa > a,
#access ul li.current-menu-item.menupremsa > a,
#access ul li.current-menu-parent.menupremsa > a {
	color: #0093be;
}

#access ul li.current_page_item.menuinedits > a,
#access ul li.current-menu-ancestor.menuinedits > a,
#access ul li.current-menu-item.menuinedits > a,
#access ul li.current-menu-parent.menuinedits > a {
	color: #5ebcbc;
}

#access ul li.current_page_item.menumicro > a,
#access ul li.current-menu-ancestor.menumicro > a,
#access ul li.current-menu-item.menumicro > a,
#access ul li.current-menu-parent.menumicro > a {
	color: #a9ce89;
}

#access ul li.current_page_item.menuconcursos > a,
#access ul li.current-menu-ancestor.menuconcursos > a,
#access ul li.current-menu-item.menuconcursos > a,
#access ul li.current-menu-parent.menuconcursos > a {
	color: #d6d940;
}

#access ul li.current_page_item.menuamics > a,
#access ul li.current-menu-ancestor.menuamics > a,
#access ul li.current-menu-item.menuamics > a,
#access ul li.current-menu-parent.menuamics > a {
	color: #01b7df;
}

#access ul li.current_page_item.menucontactar > a,
#access ul li.current-menu-ancestor.menucontactar > a,
#access ul li.current-menu-item.menucontactar > a,
#access ul li.current-menu-parent.menucontactar > a {
	color: #f60566;
}


* html #access ul li.current_page_item a,
* html #access ul li.current-menu-ancestor a,
* html #access ul li.current-menu-item a,
* html #access ul li.current-menu-parent a,
* html #access ul li a:hover {
	color: #fff;
}
