// Media queries breakpoints
$screen-xs: 0; // movil en bootstrap 480px !default;
$screen-s: 544px; // phablet default 544
$screen-m: 768px; // tablet default 768
$screen-l: 992px; // desktop
$screen-xl: 1140px; // destop-HD

// navbar-breackpoint
$breakpoint: 768px;

// Grid configuration
$container-max-width: 1360px; // max-width of container
$container-width: 100%; // max-width of container

$grid-default: 12; // number of columns
$gutter-hor: 15px; // space between columns
$gutter-ver: 15px; // space between rows


//
