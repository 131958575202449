.page-title {
	text-transform: uppercase;
	background-color: #000;
	color: #fff;
	padding: 3px 6px;
	height: 50px;
	margin-bottom: 15px;
	font-size: 16px;
	
	background-image: url("imatges/title_decoration.png");
	background-repeat: no-repeat;
	background-position: top right;
}

.page-title.orange,
.qui-som .page-title{
	background-color: #FFA24C;
}

.page-title.gray {
	background-color: #959490;
}

.page-title.brown {
	background-color: #AA7239;
}

.page-title.red {
	background-color: #E85B5B;
}

.page-title.dark-red {
	background-color: #F62653;
}

.page-title.purple {
	background-color: #9C476E;
}

.page-title.blue {
	background-color: #0093BE;
}

.page-title.blue-light,
.espais-amics .page-title{
	background-color: #00B7DF;
}


.page-title.turquoise {
	background-color: #5DBCBC;
}

.page-title.green {
	background-color: #D6D840;
}

.description-section {
	margin-bottom: 20px;
}


// ----------------------------------------------------------
// ::
// ----------------------------------------------------------

.titular-title {
	color: #fff;
	margin-top: 0px;
	font-size: 11.5px;
	text-indent: 5px;
	background-color: #000;
	background-image: url("imatges/title_decoration.png");
	background-repeat: no-repeat;
	background-position: top right;
	font-weight: bold;
	line-height: 25px;
}

.titular-title a:link,
.titular-title a:visited {
	text-color: white;
}


// ----------------------------------------------------------
// :: widget-title
// ----------------------------------------------------------
.widget-title {
	color: #fff;
	margin-top: 0px;
	font-size: 11.5px;
	text-indent: 5px;
	background-color: #000;
	background-image: url("imatges/title_decoration.png");
	background-repeat: no-repeat;
	background-position: top right;
	font-weight: bold;
	line-height: 25px;
}



// ----------------------------------------------------------
// ::
// ----------------------------------------------------------


.entry-title {
	text-align: justify;
	color: #000;
	font-size: 20px;
	line-height: 26px;
	font-weight: bold;
	margin-bottom: 5px;
	
	a{
		text-decoration: none;
		
		&:hover,
		&:active{
			color: #888888;
		}
	}
}

.post-miniatura .entry-title{
	margin-bottom: 0;
}


//.page-title {
//	color: #000;
//	font-size: 14px;
//	font-weight: bold;
//	margin: 0 0 36px 0;
//}
//
//.page-title span {
//	color: #333;
//	font-size: 16px;
//	font-style: italic;
//	font-weight: normal;
//}
//
//.page-title a:link,
//.page-title a:visited {
//	color: #888;
//	text-decoration: none;
//}
//.page-title a:active,
//.page-title a:hover {
//	color: #ff4b33;
//}

