#comments {
	margin-top: 30px;
	padding: 20px;
	background-color: #f5f5f5;
	
	#reply-title {
		margin-top: 0;
		margin-bottom: 0;
	}
}



#respond {
	border-top: 0px solid #f6f6f6;
	
	.comment-notes {
		font-size: 12px;
		font-style: italic;
		margin-bottom: 10px;
	}
	
	
	label {
		color: #888;
		font-size: 12px;
	}
	
	p {
		margin: 0;
	}
	
	textarea,
	input{
		box-sizing: border-box;
		width: 100%;
		margin: 0 0 10px;
	}
	
	.required {
		color: #ff4b33;
		font-weight: bold;
	}
	
	.comment-form-cookies-consent{
		//display: flex;
		
		#wp-comment-cookies-consent{
			float: left;
			width: auto;
			margin-top: 4px;
		}
		
		label{
			margin-left: 20px;
			display: block;
		}
	}
	
	input#submit{
		width: auto;
		padding: 5px 10px;
		text-transform: uppercase;
		margin: 0 0 10px;
		font-size: 14px;
		line-height: 15px;
		
		border: 1px solid #000000;
		color: white;
		font-weight: normal;
		background: #000000;
	}
}

// ----------------------------------------------------------
// :: comments lists
// ----------------------------------------------------------

#comments-list{
	margin-top: 30px;
}

.comment-item{
	box-sizing: border-box;
	margin-bottom: 15px;
	padding: 5px 10px 15px 10px;
	border-bottom: 3px solid #000;
}


.comment-header{
	margin-top: 5px;
	margin-bottom: 10px;
}
