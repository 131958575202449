// breackpoints mediaqueries
@mixin mquery-xl {
	@media only screen and (min-width: $screen-xl) {
		@content;
	}
}

@mixin mquery-l {
	@media only screen and (min-width: $screen-l) {
		@content;
	}
}

@mixin mquery-m {
	@media only screen and (min-width: $screen-m) {
		@content;
	}
}

@mixin mquery-s {
	@media only screen and (min-width: $screen-s) {
		@content;
	}
}

@mixin mquery-xs {
	@media only screen and (min-width: $screen-xs) {
		@content;
	}
}

@mixin breakpoint {
	@media only screen and (min-width: $breakpoint) {
		@content;
	}
}