// ----------------------------------------------------------
// :: llibre
// ----------------------------------------------------------

.post-detail-llibre{
	display: flex;
	flex-wrap: wrap;
	
	.titular-title{
		width: 100%;
		margin-bottom: 15px;
	}
	
	.portada-llibre,
	.text-llibre{
		box-sizing: border-box;
	}
	
	
	.portada-llibre{
		width: 30%;
		padding-right: 15px;
		
		img{
			width: 100%;
			height: auto;
		}
	}
	
	.text-llibre{
		width: 70%;
	}
	
}

// ----------------------------------------------------------
// :: author page
// ----------------------------------------------------------

.thumb-single-image{
	img{
		width: 100%;
		height: auto;
	}
}
